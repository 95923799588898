import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { BoxDiv, DemoBoldText, DemoClientsLogo, DemoFormWrapper, DemoHeading, DemoList, DemoListItem, DisplayStart } from '../../Components/StyledComponents';
import DemoForm from './DemoForm';
function Demo() {
    return (
        <>
            <Row>
                <Col md={6}>
                    <BoxDiv className='demo-text-content'>
                        <DemoHeading ng>Jetzt HKP-Hero kennenlernen</DemoHeading>

                        <BoxDiv className='mt-4'>
                            <DemoList>
                                <DemoListItem>
                                    Das Erstgespräch dauert 10 Minuten. Bitte suchen Sie sich einen Termin aus, an dem Sie ungestört sind.
                                </DemoListItem>
                                <DemoListItem>
                                    In diesem Gespräch finden wir gemeinsam heraus, ob Roger zu Ihnen passt. Falls ja, terminieren wir eine
                                    kostenfreie Demo, in der Sie Roger bei der Arbeit zusehen.
                                </DemoListItem>
                            </DemoList>
                        </BoxDiv>

                        <DemoBoldText>
                            Buchen Sie über den Kalender jetzt einen Termin für Ihr unverbindliches Erstgespräch!
                        </DemoBoldText>
                        <BoxDiv className='mt-5'>
                            <DisplayStart>
                                <DemoClientsLogo src="/images/Nelly_trust1.png" className='me-5' />
                                <DemoClientsLogo src="/images/Nelly_trust2.png" />
                            </DisplayStart>
                        </BoxDiv>
                    </BoxDiv>

                </Col>
                <Col md={6} className='px-0'>
                    <DemoFormWrapper>
                        <DemoForm />
                    </DemoFormWrapper>
                </Col>
            </Row>
        </>
    );
}

export default Demo;
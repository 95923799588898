import React from 'react';
import { DisplayCenter, PortfolioImgCenter, PortfolioImgLeft, PortfolioImgRight, PositionRelative } from '../../Components/StyledComponents';


function Portfolio() {
    return (
        <>
            <DisplayCenter>
                <PositionRelative>
                    <PortfolioImgCenter src='/images/Kanban.webp' />
                    <PortfolioImgLeft src='/images/PatientDetail.webp' />
                    <PortfolioImgRight src='/images/ChatHero.webp'/>
                </PositionRelative>
            </DisplayCenter>
        </>
    );
}

export default Portfolio;
import React from 'react';
import { BodyText, BoxDiv, IBWrapper, IBicon } from './StyledComponents';

function IconBox(props) {
    const { title, icon, discription } = props;

    return (
        <>
            <IBWrapper>
                <BoxDiv>
                    <IBicon src={icon} alt='icon' />
                </BoxDiv>
                <BoxDiv>
                    <BodyText FontSize="20px" FontWeight="700" TextColor="#000" mb="30px">{title}</BodyText>
                    <BodyText FontSize="17px" TextColor="#000">{discription}</BodyText>
                </BoxDiv>
            </IBWrapper>
        </>
    );
}

export default IconBox;  
import axios from "axios";
import { SERVER_URL, AWS_BUCKET_URL } from './Config';
import Swal from 'sweetalert2';

export async function dynamicPostApi(data, Url) {
  return await axios({
    method: "Post",
    url: SERVER_URL + Url,
    data: data
  }).then((response) => {
    return response;
  }).catch(function (error) {
    if (!!error.response) {
      if (error.response.status === 404) {
        Swal.fire({
          icon: 'error',
          title: 'Something went wrong...',
        });
      }
      else if (error.response.status === 500) {
        Swal.fire({
          icon: 'error',
          title: 'Something went wrong...',
        });
      }
    }
  });
}
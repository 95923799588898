import styled, { createGlobalStyle } from "styled-components";
import { Link } from 'react-router-dom';


export const GLobalcss = createGlobalStyle`
.mr-20px{
    margin-right: 20px;;
  }
  .demo-text-content{
    width:85%;
    margin-left: auto;
  }
  @media only screen and (min-width:992px){
    .w-md-77{
      width: 76% !important;
    }
  }
  @keyframes slideDown {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0);
    }
  }
  .sticky-header{
    position: sticky;
    top:0px;
    background-color: #fff !important;
    z-index: 999;
    box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.2);
    animation: slideDown 0.35s ease-out;
  }
`






export const HeaderWrapper=styled.div`
width:95%;
margin:0 auto;
display:flex;
align-items:center;
justify-content:space-between;
padding:20px 0px;
`
export const HeaderLeft=styled.div`
display:block;
`
export const HeaderRight=styled.div`
display:flex;
align-items:center;
justify-content:end;
`
export const MenuWrapper=styled.div`
display:block;
margin-right:30px;
`
export const HeaderButtonWrapper=styled.div`
display:flex;
align-items:center;
`
export const PrimaryButton=styled.div`
display:inline-block;
font-size:16px;
font-family: 'Questrial', sans-serif;
text-align:center;
background-color:#120EC4;
border:1px solid #120EC4;
color:#fff;
width:${props => props.width}px;
padding: 13px 20px;
    border-radius: 35px;
cursor: pointer!important;
&:hover{
background-color:#F5F5F5 !important;
border:1px solid #000 !important;
color:#000 !important;
}
`
export const SecondaryButton=styled.div`
display:inline-block;
font-size:16px;
font-family: 'Questrial', sans-serif;
text-align:center;
background-color:#F5F5F5;
border:1px solid #F5F5F5;
color:#120EC4;
cursor: pointer!important;
width:${props => props.width}px;
padding: 13px 20px;
    border-radius: 35px;
&:hover{
background-color:#F5F5F5 !important;
border:1px solid #000 !important;
color:#000 !important;
}
`
export const ContentWrapper=styled.div`
display:block;
`
export const ContentBox=styled.div`
width:95%;
margin:0 auto;
`

export const MenuItem=styled(Link)`
font-size:17px;
padding:8px 17px !important;
color:#000;
text-decoration:none;
$:hover{
    color:#d4d4d4 !important;
}
`
export const SiteTitle=styled.h1`
margin:0px;
font-size:${props => props.fontSize?props.fontSize+'px':'37px'};
color:#120EC4;
font-weight:700;
text-align:left;
cursor:pointer;
`
export const FooterWrapper=styled.div`
background-color:#F8F8F8;
`
export const FooterContent=styled.div`
padding-top:80px !important;
width:85%;
margin:0 auto;
`
export const DisplayBetween=styled.div`
display:flex;
align-items:center;
justify-content:space-between;
`
export const DisplayStart=styled.div`
display:flex;
align-items:${props => props.verticalAlign?props.verticalAlign:'center'};
justify-content:start;
`
export const DisplayEnd=styled.div`
display:flex;
align-items:center;
justify-content:end;
`
export const DisplayCenter=styled.div`
display:flex;
align-items:center;
justify-content:center;
`
export const FooterLink=styled.div`
font-size:17px;
color:#000;
padding:8px 0px;
text-align:left;
cursor:pointer;
&:hover{
color:#0077b8;
}
`
export const FooterLinkWrapper=styled.div`
padding-top:50px !important;
padding-bottom:50px !important;
`
export const BodyText=styled.div`
font-size:${props => props.FontSize?props.FontSize:'17px'};
color:${props => props.TextColor?props.TextColor:'#000'};
text-align:${props => props.align? props.align:'left'};
margin-bottom:${props => props.mb?props.mb:null};
font-weight:${props => props.FontWeight?props.FontWeight:'400'};
`
export const FooterBottom=styled.div`
padding-top:20px; 
padding-bottom:30px; 
`

export const FooterBottomLink=styled.div`
font-size:14px;
color:#707070;
cursor:pointer;
padding-right:10px;
`

export const DemoHeaderWrapper=styled.div`
width:85%;
margin:0 auto;
padding-top:45px;
`
export const DemoContentWrapper=styled.div`
padding-bottom:115px;
overflow-x:hidden;
padding-top:15px;
`
export const FomrControl=styled.div`
margin-bottom:20px;
`

export const FormLabel=styled.div`
font-size: ${props => props.fontSize?props.fontSize:'13px'};
color: ${props => props.color?props.color:'#33475b'};
margin-bottom:${props => props.mb?props.mb:'5px'};
text-align:${props => props.align?props.align:'left'};
`

export const TextField=styled.input.attrs(props => ({type:props.type? props.type:'text'}))`
width: 100%;
height: 40px;
padding: 9px 10px;
font-size: 16px;
font-weight: 400;
line-height: 22px;
color: #33475b;
background-color: #f5f8fa;
border: 1px solid #cbd6e2;
border-radius: 3px;
&:focus{
    outline:none;
    border: 1px solid #120EC4;
}
`
export const TextFieldContact=styled.input.attrs(props => ({type:props.type? props.type:'text'}))`
width: 100%;
height: 49px;
padding: 12px 24px;
font-size: 17px;
font-weight: 400;
line-height: 22px;
color: #000;
border: 1px solid #000;
background-color: #FFFCF8;
border-radius: 12px;
&:focus{
    outline:none;
    border: 2px solid #000;
}
`
export const TextAreaContact=styled.textarea`
width: 100%;
padding: 12px 24px;
font-size: 17px;
font-weight: 400;
line-height: 22px;
color: #000;
border: 1px solid #000;
border-radius: 12px;
background-color: #FFFCF8;
&:focus{
    outline:none;
    border: 2px solid #000;
}
`
export const RadioContact=styled.input.attrs({ type: 'radio' })`
width: 20px;
height: 20px;
vertical-align: top;
background-color: #FFFCF8;
border: 1px solid #000;
border-radius:50%;
appearance: none;
print-color-adjust: exact;
cursor:pointer;
&:checked{
background-image: url('/images/check.png');
background-color: #FFFCF8;
background-repeat: no-repeat;
background-position: center;
background-size: 10px;
}
`
export const FormSelect=styled.select`
width: 100%;
height: 40px;
padding: 9px 10px;
font-size: 16px;
font-weight: 400;
line-height: 22px;
color: #33475b;
background-color: #f5f8fa;
border: 1px solid #cbd6e2;
border-radius: 3px;
&:focus{
    outline:none;
    border: 1px solid #120EC4;
}
&:selected{
    background-color: #f5f8fa;
border: 1px solid #cbd6e2;
}
`
export const DemoFormWrapper=styled.div`
background-color: 255,255,255;
border-radius: 10px 10px 10px 10px;
box-shadow: 0.00px 1.00px 17px 0px rgba(0,0,0,0.2);
padding:60px;
`
export const Req=styled.span`
color:#f1416c !important;
`
export const DemoFormTitle=styled.h2`
color:#120EC4;
font-size:32px;
font-weight:600;
margin-bottom:50px;
`
export const DemoFormSubmit=styled.div`
display:inline-block;
    background: #0600FF;
    border-color: #0600FF;
    color: #ffffff;
    font-family: arial, helvetica, sans-serif;
    line-height: 12px;
    font-size: 12px;
    padding: 12px 24px;
    width:110px;
    margin-top:10px;
    margin-bottom:30px;
`
export const DemoHeading=styled.h1`
color:#000;
font-size:60px;
font-weight:700;
text-align:left;
margin-top:30px;
`
export const BoxDiv=styled.div`
display:block;
`
export const DemoList=styled.ul`
color:#707070;
font-size:20px;
text-align:left !important;
`
export const DemoListItem=styled.li`
margin-top:30px;
color:#707070;
font-size:20px;
text-align:left !important;
`
export const DemoBoldText=styled.div`
margin-top:30px;
color:#707070;
font-size:20px;
text-align:left !important;
font-weight:700;
`
export const DemoClientsLogo=styled.img`
height:52px;
weight:auto;
object-fit: cover;
`
export const BannerWrapper=styled.div`
padding-top:150px;
position:relative;
padding-bottom:100px;
`
export const BannerContent=styled.div`
width:70%;
margin:0 auto;
`
export const BannerTopTitle=styled.div`
font-family: 'Playfair Display', serif !important;
color: #120EC4;
font-size:30px;
`
export const BannerTitle=styled.div`
font-family: 'Playfair Display', serif !important;
color: #000;
font-weight:500;
font-size:50px;
margin-top:10px;
`
export const HomeBannerTitle=styled.div`
font-family: 'Playfair Display', serif !important;
color: #000;
font-size:60px;
font-weight:700;
margin-top:40px;
`
export const BannerDiscription=styled.div`
color: #707070;
font-size:20px;
margin-top:40px;
margin-bottom:50px;
width:90%;
margin-left:auto;
margin-right:auto;
`
export const BannerSideImage=styled.img`
width: 204px;
height: 190px;
object-fit: cover;
position:absolute;
top: 140px;
right: 15px;
transform: rotate(349deg);
`
export const PageContent=styled.div`
width: 100%;
margin-bottom:100px;
`
export const PortfolioImgCenter=styled.img`
border-radius: 13px;
width: 100%;
filter: drop-shadow(7px 7px 20px #00000026);
height:568px;
object-fit:contain;
`
export const PortfolioImgLeft=styled.img`
border-radius: 13px;
width: auto;
box-shadow: 0px 9px 21px 7px rgba(0,0,0,.1);
border: 3px solid #fff;
height:316px;
object-fit:cover;
position:absolute !important;
left: -60px;
bottom: -40px;
`
export const PortfolioImgRight=styled.img`
border-radius: 13px;
width: auto;
height:410px;
object-fit:cover;
position:absolute !important;
right:-100px;
bottom:50px;
`
export const PositionRelative=styled.div`
position:relative !important;
`
export const BlueTitle=styled.div`
font-family: 'Playfair Display', serif !important;
color: #120EC4;
font-size:30px;
margin-bottom:${props => props.mb?props.mb:'30px'};
`
export const Heading1=styled.div`
font-family: 'Playfair Display', serif !important;
color: #000;
font-size:50px;
line-height: 70px;
margin-bottom:${props => props.mb?props.mb:'30px'};
text-align:${props => props.align? props.align:'left'}
`
export const Heading2=styled.div`
font-family: 'Playfair Display', serif !important;
color: #000;
font-size:36px;
margin-bottom:${props => props.mb?props.mb:'30px'};
text-align:${props => props.align? props.align:'left'}
`
export const PhotoWrapper=styled.div`
padding-right:${props => props.left?'40px':null};
padding-lrft:${props => props.right?'40px':null};
padding-top:80px;
padding-bottom:80px;
border-radius: ${props => props.left?'0px 15px 15px 0px':'15px 0px 0px 15px'};
width:100%;
background-color:${props => props.bg};
position:relative;
left: ${props => props.left?'-22px':null};
right: ${props => props.right?'-22px':null};
`
export const RTLPhoto=styled.div`
box-shadow: ${props => props.boxShadow?'0px 9px 21px 7px rgba(0,0,0,.1)':null};
display: inline-block;
border-radius: 15px;
}
`
export const RTLContent=styled.div`
margin-left:${props => props.right?'100px':'70px'};
width:${props => props.width?props.width:null};
}
`
export const TitleImg=styled.img`
margin-bottom:10px;
`
export const IBicon=styled.img`
margin-right:30px;
`
export const IBWrapper=styled.div`
display:flex;
align-items:start;
justify-content:start;
`
export const CleanSectionWrapper=styled.div`
width: 100%;
margin-bottom:100px;
padding-top:90px;
padding-bottom:120px;
background-color:#fbf4eb;
`
export const TitleBoxWrapper=styled.div`
background-color:${props => props.bg?props.bg:null};
padding:45px 30px;
border-radius:8px;
margin:0 35px;
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
`
export const TitleBoxImg=styled.img`
margin-bottom:20px;
width: 150px;
height: 148px;
object-fit: cover;
object-position: 50% 50%;
`
export const DemoRequestWrapper=styled.div`
width: 100%;
padding-top:150px;
padding-bottom:120px;
background-color:#120ec4;
`
export const DemoRequestTitle=styled.div`
font-family: 'Playfair Display', serif !important;
color: #fff;
font-size:60px;
line-height: 75px;
margin-bottom:${props => props.mb?props.mb:'30px'};
text-align:${props => props.align? props.align:'left'}
`
export const WhiteButton=styled.div`
display:inline-block;
font-size:${props => props.fontSize?props.fontSize:'16px'};
font-family: 'Questrial', sans-serif;
font-weight:600;
letter-spacing:0.5px;
text-align:center;
background-color:#fff;
border:1px solid #fff;
color:#120EC4;
cursor: pointer!important;
width:${props => props.width}px;
padding: ${props => props.Padding?props.Padding:'13px 20px'};
border-radius: 35px;
margin-top:${props => props.mt?props.mt:null};
margin-bottom:${props => props.mb?props.mb:null};
&:hover{
background-color:#fff !important;
border:1px solid #000 !important;
color:#000 !important;
}
`
export const TestimonialImg=styled.img`
height:80px;
weight:80px;
border-radius:50%;
object-fit: cover;
`
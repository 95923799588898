import React from 'react';
import ContactForm from '../../Components/ContactForm';
import CenterHeading from '../../Components/CenterHeading';
import { BoxDiv } from '../../Components/StyledComponents';
function ContactSection(props) {
    return (
        <>
            <BoxDiv style={{ marginTop: props.mt + 'px', marginBottom: props.mb + 'px', width: props.width + '%', marginLeft: 'auto', marginRight: 'auto' }}>
                <CenterHeading
                    blueHeading='Kontakt'
                    mainHeading='Sprechen Sie mit HKP-Hero'
                    mainHeadingMB='80px'
                />
                <ContactForm />
            </BoxDiv>
        </>
    );
}

export default ContactSection;
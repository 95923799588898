import React from 'react';
import { BoxDiv, CleanSectionWrapper } from '../../Components/StyledComponents';
import Container from 'react-bootstrap/Container'
import { Col, Row } from 'react-bootstrap';
import TitleBox from '../../Components/TitleBox';
import CenterHeading from '../../Components/CenterHeading';


const TitleBoxList = [
    {
        icon: '/images/TitleBox1.webp',
        title: 'Schnittstellen zur </br> Praxis-Software',
        bgColor: '#FFFCF8'
    },
    {
        icon: '/images/TitleBox2.webp',
        title: '100% DSGVO </br> konform',
        bgColor: '#FFFCF8'
    },
    {
        icon: '/images/TitleBox3.webp',
        title: 'Deutsche </br> Server',
        bgColor: '#FFFCF8'
    },
]

function CleanSection(props) {
    return (
        <>
            <CleanSectionWrapper>
                <Container>
                    <Row>
                        <Col md={12}>
                            <BoxDiv style={{ marginTop: props.mt + 'px', width: props.width + '%', marginLeft: 'auto', marginRight: 'auto' }}>
                                <CenterHeading
                                    blueHeading='Für den Seelenfrieden'
                                    mainHeading='Saubere Arbeit von A bis Z'
                                    discription='Die Roger Software unterliegt dem Deutschen Standard für Datensicherheit.
                                    Unsere Daten lagern zu 100% verschlüsselt auf Servern in Frankfurt.
                                    Sensible Patientendaten werden niemals ohne Einverständnis verwendet.'
                                />
                            </BoxDiv>
                        </Col>
                        <Col md={11} className='mx-auto mt-5'>
                            <Row>
                                {TitleBoxList.map((item, index) => {
                                    return <Col md={4} key={`title-box-item-${index}`}>
                                        <TitleBox icon={item.icon} title={item.title} bgColor={item.bgColor} />
                                    </Col>
                                })}
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </CleanSectionWrapper>

        </>
    );
}

export default CleanSection;
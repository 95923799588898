import React from 'react';
import Banner from '../../Components/Banner';
import { BoxDiv, ContentBox, PageContent } from '../../Components/StyledComponents';
import { Container } from 'react-bootstrap';
import ContactForm from '../../Components/ContactForm';


function Contact() {
    return (
        <>
            <ContentBox>
                <Banner
                    bannerTopTitle="Kontakt"
                    bannerTitle="Sprechen Sie mit HKP-Hero."
                />
                <PageContent>
                    <Container>
                        <BoxDiv style={{  marginTop: '20px',width:'90%', marginLeft: 'auto', marginRight: 'auto' }}>
                            <ContactForm />
                        </BoxDiv>
                    </Container>
                </PageContent>
            </ContentBox>

        </>
    );
}

export default Contact;
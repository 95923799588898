import React from 'react';
import Banner from '../../Components/Banner';
import RTLSection from '../Home/RTLSection';
import { ContentBox } from '../../Components/StyledComponents';
import ContactSection from './ContactSection';

function About(props) {
    return (
        <>
            <ContentBox>
                <Banner
                    bannerTopTitle="Über uns"
                    bannerTitle="Gemeinsam für Zahnärzte"
                />
                <RTLSection
                    sectionMT="20px"
                    textRight={true}
                    BgColor='#fbf4eb'
                    Image='/images/ourMission.webp'
                    TextContentWidth="68%"
                    heading='Unsere Mission'
                    subHeading='70% aller HKP in Deutschland werden nie umgesetzt. Das möchte HKP-Hero ändern.'
                    discription='Einem Behandlungsplan geht viel Arbeit voraus: Befund, Beratungsgespäch, Ankaufsvoranfrage, Laboranfrage, Leistungserfassung und Erstellung des Plans. Schade, dass hierbei lediglich 30% aller aufgestellten HKP umgesetzt werden. Das wollen wir ändern und haben hierfür HKP-Hero entwickelt. HKP-Hero integriert nahtlos mit Ihrer Praxis-Verwaltung-Software und erweitert diese. So werden Sie Herr (und Frau) der Lage und steuern Ihre Behandlungspläne von zuhause aus.'
                    photoShadow={true}
                    imgRounded={true}
                />
                <RTLSection
                    sectionMT="200px"
                    textRight={false}
                    BgColor='#fff5f5'
                    Image='/images/ourTeam.webp'
                    TextContentWidth="68%"
                    heading='Unser Team'
                    subHeading='Teamorientiert und technikverliebt: Das ist Sanos Group AG als Arbeitgeber und Geschäftspartner.'
                    discription='Entwickelt und vermarktet wird HKP-Hero vom Konzern Sanos Group AG. Hinter dem HKP-Hero Team stehen Unternehmer und Zahnmediziner aus der Schweiz, Österreich und Deutschland. Damit stellen wir sicher, dass unsere Produkte nicht nur technologisch zeitgemäß sind, sondern auch die Bedürfnisse der DACH-Branche perfekt abdecken. Wir wollen die besten digitalen Lösungen für die Gesundheitsbranche kreieren. Wir agieren zusammen, um Produkte und Abläufe immer weiter zu verbessern. Sollten Sie an einer Zusammenarbeit interessiert sein, nehmen Sie gerne Kontakt mit uns auf.'
                    photoShadow={true}
                    imgRounded={true}
                />
                <RTLSection
                    sectionMT="200px"
                    textRight={true}
                    BgColor='#f5f9ff'
                    Image='/images/affiliateProgram.webp'
                    TextContentWidth="68%"
                    heading='Unser Partnerprogramm'
                    subHeading='Schwierige Problemen gemeinsam zu lösen, macht mehr Spass und erhöht die Qualität. '
                    discription='Medizinische Software lässt sich nur gemeinsam erfolgreich bauen. Zu diesem Zwecke führen wir ein Partnerprogramm mit Zahnarztpraxen und medizinischen Beratern, die gemeinsam mit uns an der Entwicklung und das Testen unserer Software arbeiten. Dazu gehören Europas größte Praxen und Beratungen. Wenn auch Sie unser Partner werden wollen, können Sie unten Kontakt aufnehmen. Partner erleben moderne Produktentwicklung hautnah mit und erfreuen sich über die neusten Module.'
                    photoShadow={true}
                    imgRounded={true}
                />
                <ContactSection width={70} mt={100} mb={100}/>
            </ContentBox>
        </>
    );
}

export default About;
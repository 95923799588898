import React, { useEffect, useState } from 'react';
import { HeaderWrapper, HeaderButtonWrapper, HeaderLeft, HeaderRight, MenuWrapper, PrimaryButton, SecondaryButton, SiteTitle } from '../../Components/StyledComponents';
import MainMenu from './MainMenu';
import { Link } from "react-router-dom";
import { REACT_APP_URL } from '../../Config';
import { useNavigate } from 'react-router-dom';

function Header() {
    const navigate = useNavigate();

    const [top, setTop] = useState(false);
    useEffect(() => {
        document.addEventListener('scroll', () => {
            const scrollTop = document.documentElement.scrollTop;
            if (scrollTop > 10) {
                setTop(true)
            } else {
                setTop(false)
            }
        });
    }, []);
    return (
        <>
            <div className={!!top ? 'sticky-header' : null}>
                <HeaderWrapper>
                    <HeaderLeft>
                        <SiteTitle onClick={() => navigate('/')}>HKP-Hero</SiteTitle>
                    </HeaderLeft>
                    <HeaderRight>
                        <MenuWrapper>
                            <MainMenu />
                        </MenuWrapper>
                        <HeaderButtonWrapper>
                            <a href={REACT_APP_URL}><SecondaryButton width="169" fontSize="17" className='mr-20px'>Einloggen </SecondaryButton></a>
                            <PrimaryButton onClick={() => navigate('demo')} width="169" fontSize="17" >Kostenlose Demo</PrimaryButton>
                        </HeaderButtonWrapper>
                    </HeaderRight>
                </HeaderWrapper>
            </div>
        </>
    );
}

export default Header;
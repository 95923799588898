import React from 'react';
import { BannerContent, BannerDiscription, BannerSideImage, BannerTitle, BannerTopTitle, BannerWrapper, HomeBannerTitle, PrimaryButton, bannerTopTitle } from './StyledComponents';
import { useNavigate } from 'react-router-dom';

function Banner(props) {
    const { type, bannerTopTitle, bannerTitle, bannerDiscription } = props;
    const navigate = useNavigate();
    return (
        <>
            <BannerWrapper>
                <BannerContent>
                    <BannerTopTitle>{bannerTopTitle}</BannerTopTitle>
                    {type === 'home' ?
                        <HomeBannerTitle>{bannerTitle}</HomeBannerTitle>
                        :
                        <BannerTitle>{bannerTitle}</BannerTitle>
                    }
                    {!!bannerDiscription ?
                        <BannerDiscription>
                            {bannerDiscription}
                        </BannerDiscription>
                        :
                        null

                    }
                    {type === 'home' ?
                        <PrimaryButton onClick={() => navigate('demo')} width="199" fontSize="17">Kostenlose Demo</PrimaryButton>
                        :
                        null
                    }
                    {type === 'home' ?
                        <BannerSideImage src="/images/HomeBanner.webp" />
                        :
                        null
                    }
                </BannerContent>
            </BannerWrapper>
        </>
    );
}

export default Banner;
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import MainLayout from './Layouts/MainLayout/MainLayout';
import Home from './Pages/Home/Home';
import DemoLayout from './Layouts/DemoLayout/DemoLayout';
import Demo from './Pages/Demo/Demo';
import About from './Pages/About/About';
import Contact from './Pages/Contact/Contact';

function App() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }
  }, [pathname]);


  return (
    <div className="App">

      <Routes>
        <Route element={<MainLayout/>}>
          <Route index element={<Home/>}/>
          <Route path='about' element={<About/>}/>
          <Route path='contact' element={<Contact/>}/>
        </Route>
        <Route element={<DemoLayout/>}>
          <Route path='demo' element={<Demo/>}/>
        </Route>
      </Routes>
    </div>
  );
}

export default App;

import React from 'react';
import { BlueTitle, BodyText, DisplayCenter, Heading1, TitleImg } from './StyledComponents';

function CenterHeading(props) {
    const { image, blueHeading, blueHeadingMB, mainHeading, mainHeadingMB, discription } = props;
    return (
        <>
            {!!image ?
                <DisplayCenter>
                    <TitleImg src={image} alt='image' />
                </DisplayCenter>
                :
                null
            }
            {!!blueHeading ?
                <BlueTitle mb={!!blueHeadingMB ? blueHeadingMB : '10px'}>{blueHeading}</BlueTitle>
                :
                null
            }
            {!!mainHeading ?
                <Heading1 align="center" mb={!!mainHeadingMB ? mainHeadingMB : '30px'}>{mainHeading}</Heading1>
                :
                null
            }
            {!!discription ?
                <BodyText align="center" FontSize="20px" FontWeight="500" TextColor="#707070">
                    {discription}
                </BodyText>
                :
                null
            }
        </>
    );
}

export default CenterHeading;
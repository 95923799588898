import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { DisplayCenter, DisplayStart, FomrControl, FormLabel, PrimaryButton, RadioContact, TextAreaContact, TextFieldContact, Req } from './StyledComponents';
import { useFormik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { dynamicPostApi } from '../Api';

function ContactForm(props) {

    const [isChecked, setIsChecked] = useState('');
    const formik = useFormik({
        initialValues: {
            fullname: '',
            jobTitle: '',
            email: '',
            phone: '',
            message: ''
        },
        validationSchema: Yup.object().shape({
            fullname: Yup.string().required("Name is required"),
            jobTitle: Yup.string().required("Job title is required"),
            email: Yup.string().required("Email is required").email("Email Address is invalid"),
            phone: Yup.string().required("Phone Number is required"),
            message: Yup.string().required("Message is required").min(50, "Write at least 50 characters")
        }),
        onSubmit: async (values, { resetForm }) => {
            if (isChecked !== "") {
                let url = "api/contact/addContact"
                let result = await dynamicPostApi(values, url);
                if (result) {
                    resetForm();
                    setIsChecked('');
                }
            } else {

                setIsChecked('missing');
            }

        }
    });

    function handleRadio(e) {
        setIsChecked(e.target.value)
    }

    return (
        <>
            <Row>
                <Col md={6}>
                    <FomrControl>
                        <FormLabel fontSize='17px' color='#000'>Name <Req> *</Req></FormLabel>
                        <TextFieldContact name='fullname' type='text'
                            value={formik.values.fullname}
                            onChange={formik.handleChange} />
                        <FormLabel> <Req>{formik.errors.fullname}</Req> </FormLabel>
                    </FomrControl>
                </Col>
                <Col md={6}>
                    <FomrControl>
                        <FormLabel fontSize='17px' color='#000'>Berufsbezeichnung</FormLabel>
                        <TextFieldContact name='jobTitle' type='text'
                            value={formik.values.jobTitle}
                            onChange={formik.handleChange} />
                        <FormLabel> <Req>{formik.errors.jobTitle}</Req> </FormLabel>
                    </FomrControl>
                </Col>
                <Col md={6}>
                    <FomrControl>
                        <FormLabel fontSize='17px' color='#000'>E-Mail *</FormLabel>
                        <TextFieldContact name='email' type='text'
                            value={formik.values.email}
                            onChange={formik.handleChange} />
                        <FormLabel> <Req>{formik.errors.email}</Req> </FormLabel>
                    </FomrControl>
                </Col>
                <Col md={6}>
                    <FomrControl>
                        <FormLabel fontSize='17px' color='#000'>Telefon *</FormLabel>
                        <TextFieldContact name='phone' type='text'
                            value={formik.values.phone}
                            onChange={formik.handleChange} />
                        <FormLabel> <Req>{formik.errors.phone}</Req> </FormLabel>
                    </FomrControl>
                </Col>
                <Col md={12}>
                    <FomrControl>
                        <FormLabel fontSize='17px' color='#000'>Ihre Nachricht</FormLabel>
                        <TextAreaContact name='message' rows='10'
                            value={formik.values.message}
                            onChange={formik.handleChange} />
                        <FormLabel> <Req>{formik.errors.message}</Req> </FormLabel>
                    </FomrControl>
                </Col>
                <Col md={12}>
                    <FomrControl>
                        <DisplayStart>
                            <RadioContact type='radio' name='isPrivacyPolicy' id='isPrivacyPolicy' className='me-3'
                                value="accept"
                                checked={isChecked === "accept"}
                                onChange={handleRadio} />
                            <FormLabel fontSize='17px' color='#000' htmlFor='isPrivacyPolicy'>Ich habe die Datenschutzerklärung zur Kenntnis genommen.</FormLabel>


                        </DisplayStart>
                        {
                            isChecked === "missing" ? <div><FormLabel><br /> <Req>Please accept our terms and conditions</Req> </FormLabel></div> : null
                        }
                    </FomrControl>
                </Col>
                <Col md={12}>
                    <FomrControl>
                        <DisplayCenter>
                            <PrimaryButton width="275" fontSize="17" onClick={formik.handleSubmit}>Absenden</PrimaryButton>
                        </DisplayCenter>
                    </FomrControl>
                </Col>
            </Row>
        </>
    );
}

export default ContactForm;
import React from 'react';
import { FomrControl, FormLabel, TextField, FormSelect, Req, DemoFormTitle, DemoFormSubmit, DisplayStart } from '../../Components/StyledComponents';
import { Col, Row } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { dynamicPostApi } from '../../Api';

function DemoForm() {

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            mobile: '',
            demoType: '',
            numberOfEmployee: 0
        },
        validationSchema: Yup.object().shape({
            firstName: Yup.string().required("First Name is required"),
            lastName: Yup.string().required("Last Name is required"),
            email: Yup.string().required("Email is required").email("Enter a valid Email Address"),
            mobile: Yup.string().required("Mobile Number is required"),
            demoType: Yup.string().required("Select an option"),
            numberOfEmployee: Yup.number().required("Enter number of employees"),
        }),
        onSubmit: async (values, { resetForm }) => {
            let url = "api/demoRequest/addDemoRequest"
            let result = await dynamicPostApi(values, url);
            if (result) {
                resetForm();
            }
        },
    });

    function onTypeChange(e) {
        formik.setFieldValue("demoType", e.target.value);
    }

    return (
        <>
            <Row>
                <Col md={12} className='ps-0'>
                    <DemoFormTitle>Kostenloses Erstgespräch vereinbaren</DemoFormTitle>
                </Col>
                <Col md={6} className='ps-0'>
                    <FomrControl>
                        <FormLabel>Vomame <Req> *</Req></FormLabel>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            placeholder='First Name'
                            id="firstName"
                            name="firstName"
                            size="small"
                            sx={{ mb: 2, height: '45px' }}
                            error={formik.errors.firstName && formik.touched.firstName ? true : false}
                            helperText={formik.errors.firstName && formik.touched.firstName ? formik.errors.firstName : ""}
                            value={formik.values.firstName}
                            onChange={formik.handleChange}
                        />
                        <FormLabel> <Req>{formik.errors.firstName}</Req> </FormLabel>
                    </FomrControl>
                </Col>
                <Col md={6} className='pe-0'>
                    <FomrControl>
                        <FormLabel>Nachname <Req>*</Req></FormLabel>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            placeholder='Last Name'
                            id="lastName"
                            name="lastName"
                            size="small"
                            sx={{ mb: 2, height: '45px' }}
                            error={formik.errors.lastName && formik.touched.lastName ? true : false}
                            helperText={formik.errors.lastName && formik.touched.lastName ? formik.errors.lastName : ""}
                            value={formik.values.lastName}
                            onChange={formik.handleChange}
                        />
                        <FormLabel> <Req>{formik.errors.lastName}</Req> </FormLabel>
                    </FomrControl>
                </Col>
                <Col md={6} className='ps-0'>
                    <FomrControl>
                        <FormLabel>E-Mail <Req>*</Req></FormLabel>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            placeholder='E-Mail'
                            id="email"
                            name="email"
                            size="small"
                            sx={{ mb: 2, height: '45px' }}
                            error={formik.errors.email && formik.touched.email ? true : false}
                            helperText={formik.errors.email && formik.touched.email ? formik.errors.email : ""}
                            value={formik.values.email}
                            onChange={formik.handleChange} />
                        <FormLabel> <Req>{formik.errors.email}</Req> </FormLabel>
                    </FomrControl>
                </Col>
                <Col md={6} className='pe-0'>
                    <FomrControl>
                        <FormLabel>Mobilnummer <Req>*</Req></FormLabel>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            placeholder='Mobile'
                            id="mobile"
                            name="mobile"
                            size="small"
                            sx={{ mb: 2, height: '45px' }}
                            error={formik.errors.mobile && formik.touched.mobile ? true : false}
                            helperText={formik.errors.mobile && formik.touched.mobile ? formik.errors.mobile : ""}
                            value={formik.values.mobile}
                            onChange={formik.handleChange} />
                        <FormLabel> <Req>{formik.errors.mobile}</Req> </FormLabel>
                    </FomrControl>
                </Col>
                <Col md={12} className='px-0'>
                    <FomrControl>
                        <FormLabel>Welche Praxis Software nutzen Sie? <Req>*</Req></FormLabel>
                        <FormSelect name='demoType' onChange={onTypeChange}
                            size="small"
                            sx={{ mb: 2, height: '45px' }}
                            error={formik.errors.demoType && formik.touched.demoType ? true : false}
                            helperText={formik.errors.demoType && formik.touched.demoType ? formik.errors.demoType : ""}
                            value={formik.values.demoType}>
                            <option>option 1</option>
                            <option>option 2</option>
                            <option>option 3</option>
                            <option>option 4</option>
                            <option>option 5</option>
                        </FormSelect>
                        <FormLabel> <Req>{formik.errors.demoType}</Req> </FormLabel>
                    </FomrControl>
                </Col>
                <Col md={12} className='px-0'>
                    <FomrControl>
                        <FormLabel>Wieviele Zahnärzte beschäftigen Sie? <Req>*</Req></FormLabel>
                        <TextField type='number' name='numberOfEmployee'
                            margin="normal"
                            required
                            fullWidth
                            placeholder='Mobile'
                            id="numberOfEmployee"
                            size="small"
                            sx={{ mb: 2, height: '45px' }}
                            error={formik.errors.numberOfEmployee && formik.touched.numberOfEmployee ? true : false}
                            helperText={formik.errors.numberOfEmployee && formik.touched.numberOfEmployee ? formik.errors.numberOfEmployee : ""}
                            value={formik.values.numberOfEmployee}
                            onChange={formik.handleChange} />
                        <FormLabel> <Req>{formik.errors.numberOfEmployee}</Req> </FormLabel>
                    </FomrControl>
                </Col>
                <Col md={12} className='ps-0'>
                    <DisplayStart>
                        <DemoFormSubmit type="submit" onClick={formik.handleSubmit}>Absenden</DemoFormSubmit>
                    </DisplayStart>
                </Col>
            </Row>
        </>
    );
}

export default DemoForm;
import React from 'react';
import { Outlet } from 'react-router-dom';
import { DemoContentWrapper, DemoHeaderWrapper, GLobalcss } from '../../Components/StyledComponents';
import DemoHeader from './DemoHeader';

function DemoLayout() {
    return (
        <>
            <GLobalcss />
            <DemoHeader />
            <DemoContentWrapper>
                <Outlet />
            </DemoContentWrapper>
        </>
    );
}

export default DemoLayout;
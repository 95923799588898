import React from 'react';
import { BodyText, BoxDiv, DemoRequestTitle, DemoRequestWrapper, WhiteButton } from '../../Components/StyledComponents';
import { useNavigate } from 'react-router-dom';

function DemoRequestSection(props) {
    const navigate=useNavigate();
    return (
        <>
            <DemoRequestWrapper>
                <DemoRequestTitle mb='50px' align='center'>
                    Jetzt kostenlose Demo anfragen
                </DemoRequestTitle>
                <BoxDiv style={{ marginTop: props.mt + 'px', width: props.width + '%', marginLeft: 'auto', marginRight: 'auto' }}>
                    <BodyText align="center" FontSize="20px" FontWeight="500" TextColor="#fff">
                        Roger ist eine Software für ZMVs und Zahnärzte. Roger erinnert Patienten selbständig an
                        überfällige Behandlungspläne. So erhöht er den Umsatz und entlastet das Team. Jeden Tag.
                    </BodyText>
                </BoxDiv>
                <WhiteButton onClick={()=>navigate('demo')} width='275' fontSize='20px' Padding='20px 20px' mt='70px' mb='50px'>Kostenlose Demo</WhiteButton>
            </DemoRequestWrapper>
        </>
    );
}

export default DemoRequestSection;
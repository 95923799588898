import React from 'react';
import {BoxDiv} from '../../Components/StyledComponents';
import CenterHeading from '../../Components/CenterHeading';

function WhyUs(props) {
    return (
        <>
            <BoxDiv style={{ marginTop: props.mt + 'px', width: props.width + '%', marginLeft: 'auto', marginRight: 'auto' }}>
                <CenterHeading
                    blueHeading='Warum HKP-Hero?'
                    mainHeading='Patienten freundlich und wirtschaftlich verwalten'
                    mainHeadingMB='100px'
                />
            </BoxDiv>
        </>
    );
}

export default WhyUs;
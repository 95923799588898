import React from 'react';
import { BodyText, DisplayBetween, DisplayStart, FooterBottom, FooterBottomLink, FooterContent, FooterLink, FooterLinkWrapper, FooterWrapper, PrimaryButton, SiteTitle } from '../../Components/StyledComponents';
import { Col, Row } from 'react-bootstrap';
import { BsDot } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';

function Footer() {
    const navigate=useNavigate();
    return (
        <>
            <FooterWrapper>
                <FooterContent>
                    <SiteTitle>HKP-Hero</SiteTitle>
                    <FooterLinkWrapper>
                        <Row>
                            <Col md={3}>
                                <DisplayStart>
                                    <div className='me-5'>
                                        <FooterLink>Über uns</FooterLink>
                                        <FooterLink>Kontakt</FooterLink>
                                        <FooterLink>Einloggen</FooterLink>
                                    </div>
                                    <div>
                                        <FooterLink>Jobs</FooterLink>
                                        <FooterLink>News</FooterLink>
                                        <FooterLink>Datenschutz</FooterLink>
                                    </div>
                                </DisplayStart>
                                <DisplayStart className='mt-3'>
                                    <PrimaryButton onClick={()=>navigate('demo')} width="169" fontSize="17">Kostenlose Demo</PrimaryButton>
                                </DisplayStart>
                            </Col>
                            <Col md={6}>
                                <BodyText>Sanos Group AG</BodyText>
                                <FooterLink>sales@hkphero.com</FooterLink>
                                <FooterLink>+49 30 311 982 58</FooterLink>
                            </Col>
                            <Col md={3}>
                                <DisplayStart>
                                    <img src='/images/footerimg.webp' />
                                </DisplayStart>
                            </Col>
                        </Row>
                    </FooterLinkWrapper>
                    <FooterBottom>
                        <DisplayStart>
                        <FooterBottomLink>Impressum</FooterBottomLink>
                        <BsDot size={18} color='#707070'/>
                        <FooterBottomLink>Datenschutzerklérung</FooterBottomLink>
                        <BsDot size={18} color='#707070'/>
                        <FooterBottomLink>Allgemeine Geschäftsbedingungen</FooterBottomLink>
                        </DisplayStart>
                    </FooterBottom>
                </FooterContent>
            </FooterWrapper>
        </>
    );
}

export default Footer;
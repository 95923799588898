import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { BodyText, DisplayCenter, DisplayEnd, DisplayStart, Heading2, PhotoWrapper, PrimaryButton, RTLContent, RTLPhoto, photoShadow } from '../../Components/StyledComponents';
import { useNavigate } from 'react-router-dom';

function RTLSection(props) {
    const { sectionMT, textRight, BgColor, Image, TextContentWidth, heading, subHeading, discription, buttonText, buttonLink, photoShadow, photoCenter,imgRounded } = props;
    const navigate=useNavigate();
    return (
        <>
            {!!textRight ?
                <Row style={{ marginTop: sectionMT }}>
                    <Col md={6} className='px-0'>
                        <PhotoWrapper bg={BgColor} left={textRight}>
                            {photoCenter ?
                                <DisplayCenter>
                                    <RTLPhoto left={textRight} boxShadow={photoShadow}>
                                        <img src={Image} alt='image' style={{borderRadius:imgRounded?'0px 15px 15px 0px':null}}/>
                                    </RTLPhoto>
                                </DisplayCenter>
                                :
                                <DisplayStart>
                                    <RTLPhoto left={textRight} boxShadow={photoShadow}>
                                        <img src={Image} alt='image' style={{borderRadius:imgRounded?'0px 15px 15px 0px':null}}/>
                                    </RTLPhoto>
                                </DisplayStart>
                            }

                        </PhotoWrapper>
                    </Col>
                    <Col md={6} className='align-self-md-center'>
                        <RTLContent right={textRight} width={TextContentWidth}>
                            {!!heading ?
                                <Heading2>{heading}</Heading2>
                                :
                                null
                            }
                            {!!subHeading ?
                                <BodyText FontSize='20px' TextColor='#707070' mb='30px' FontWeight="500">
                                    {subHeading}
                                </BodyText>
                                :
                                null
                            }
                            {!!discription ?
                                <BodyText FontSize='18px' mb="30px">
                                    {discription}
                                </BodyText>
                                :
                                null
                            }
                            {!!buttonText ?
                                <DisplayStart>
                                    <PrimaryButton onClick={()=>navigate(buttonLink)}  width="175" fontSize="17">{buttonText}</PrimaryButton>
                                </DisplayStart>
                                :
                                null
                            }
                        </RTLContent>
                    </Col>
                </Row>
                :
                <Row style={{ marginTop: sectionMT }}>
                    <Col md={6} className='align-self-md-center'>
                        <RTLContent right={textRight} width={TextContentWidth}>
                            {!!heading ?
                                <Heading2>{heading}</Heading2>
                                :
                                null
                            }
                            {!!subHeading ?
                                <BodyText FontSize='20px' TextColor='#707070' mb='30px' FontWeight="500">
                                    {subHeading}
                                </BodyText>
                                :
                                null
                            }
                            {!!discription ?
                                <BodyText FontSize='18px' mb="30px">
                                    {discription}
                                </BodyText>
                                :
                                null
                            }
                            {!!buttonText ?
                                <DisplayStart>
                                    <PrimaryButton onClick={()=>navigate(buttonLink)} width="175" fontSize="17">{buttonText}</PrimaryButton>
                                </DisplayStart>
                                :
                                null
                            }
                        </RTLContent>
                    </Col>
                    <Col md={6} className='px-0'>
                        <PhotoWrapper bg={BgColor} right={!textRight}>
                            {photoCenter ?
                                <DisplayCenter>
                                    <RTLPhoto right={!textRight} boxShadow={photoShadow}>
                                        <img src={Image} alt='image' style={{borderRadius:imgRounded?'15px 0px 0px 15px':null}}/>
                                    </RTLPhoto>
                                </DisplayCenter>
                                :
                                <DisplayEnd>
                                    <RTLPhoto right={!textRight} boxShadow={photoShadow}>
                                        <img src={Image} alt='image' style={{borderRadius:imgRounded?'15px 0px 0px 15px':null}}/>
                                    </RTLPhoto>
                                </DisplayEnd>
                            }
                        </PhotoWrapper>
                    </Col>
                </Row>
            }

        </>
    );
}

export default RTLSection;
import React from 'react';
import Banner from '../../Components/Banner';
import { ContentBox, PageContent } from '../../Components/StyledComponents';
import { Col, Container, Row } from 'react-bootstrap';
import Portfolio from './Portfolio';
import WhyUs from './WhyUs';
import RTLSection from './RTLSection';
import ModulesSection from './ModulesSection';
import CleanSection from './CleanSection';
import TestimonialSection from './TestimonialSection';
import DemoRequestSection from './DemoRequestSection';

function Home() {
    return (
        <>
            <ContentBox>
                <Banner
                    type="home"
                    bannerTopTitle="Die digitale Zahnarztpraxis"
                    bannerTitle="Behandlungspläne automatisch nachverfolgt. Mit HKP-Hero."
                    bannerDiscription="HKP-Hero ergänzt Ihr Team um einen virtuellen Mitarbeiter. Er entlastet Sie bei der Verwaltung von Behandlungsplänen und erhöht so den Umsatz. Jeden Tag."
                />
                <PageContent>
                    <Container>
                        <Row className='px-3'>
                            <Col lg={9} className='mx-auto'>
                                <Portfolio />
                            </Col>
                            <Col lg={12}>
                                <WhyUs mt={160} width={60} />
                            </Col>
                        </Row>
                    </Container>
                    <Row>
                        <Col lg={12}>
                            <RTLSection
                                sectionMT="0px"
                                textRight={true}
                                BgColor='#fbf4eb'
                                Image='/images/Kanban.webp'
                                TextContentWidth="68%"
                                heading='Behandlungspläne'
                                subHeading='HKP-Hero geht Behandlungsplänen zeitnah nach. Selbständig und freundlich.'
                                discription='Lassen Sie Ihre wichtigsten Patienten nicht aus den Augen. Dank Schnittstellen zu Ihrem Praxissystem erinnert HKP-Hero Ihre Patienten automatisch an überfällige Behandlungspläne.'
                                buttonText='Kostenlose Demo'
                                buttonLink='/demo'
                                photoShadow={true}
                            />
                        </Col>
                        <Col lg={12}>
                            <RTLSection
                                sectionMT="110px"
                                textRight={false}
                                BgColor='#fff5f5'
                                Image='/images/Task.webp'
                                TextContentWidth="68%"
                                heading='Aufgaben'
                                subHeading='Lassen Sie sich von HKP-Hero leiten. Er erstellt die richtigen Aufgaben für Sie automatisch.'
                                discription='HKP-Hero erstellt falls nötig Aufgaben für relevante Pläne und Patienten. Überprüfen Sie erledigte und überfällige Aufgaben jederzeit und handeln Sie wo nötig. '
                                buttonText='Kostenlose Demo'
                                buttonLink='/demo'
                                photoShadow={true}
                            />
                        </Col>
                        <Col lg={12}>
                            <RTLSection
                                sectionMT="110px"
                                textRight={true}
                                BgColor='#F5F9FF'
                                Image='/images/ChatModule.webp'
                                TextContentWidth="68%"
                                heading='Kommunikation'
                                subHeading='Ihre Patienten sind auf Whatsapp. HKP-Hero auch und zwar DSGVO-konform.'
                                discription='Kontaktieren Sie Ihre Patienten noch schneller über Business-WhatsApp. So wissen Sie stets woran Sie sind und können Behandlungen noch schneller umsetzen.'
                                buttonText='Kostenlose Demo'
                                buttonLink='/demo'
                                photoShadow={false}
                                photoCenter={true}
                            />
                        </Col>
                        <Col lg={12}>
                            <ModulesSection mt={150} width={60} />
                        </Col>
                    </Row>
                </PageContent>
            </ContentBox>
            
            <CleanSection width={65}/>

            <ContentBox>
                <PageContent>
                    <TestimonialSection mt={170} width={48}/>
                </PageContent>
            </ContentBox>

            <DemoRequestSection width={47}/>
        </>
    );
}

export default Home;
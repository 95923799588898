import React from 'react';
import { BodyText, TitleBoxImg, TitleBoxWrapper } from './StyledComponents';

function TitleBox(props) {
    const {icon, title,bgColor}=props;

    return (
        <>
        <TitleBoxWrapper bg={bgColor}>
            <TitleBoxImg src={icon} alt='icon'/>
            <BodyText FontSize="20px" TextColor="#000" align="center" dangerouslySetInnerHTML={{__html: title}}></BodyText>
        </TitleBoxWrapper>
        </>
    );
}

export default TitleBox;
import React from 'react';
import { DemoHeaderWrapper, SiteTitle } from '../../Components/StyledComponents';

function DemoHeader() {
    return ( 
        <>
          <DemoHeaderWrapper>
              <SiteTitle fontSize="26">HKP-Hero</SiteTitle>
          </DemoHeaderWrapper>
        </>
     );
}

export default DemoHeader;
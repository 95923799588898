import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { MenuItem } from '../../Components/StyledComponents';

function MainMenu() {
  return (
    <Navbar expand="lg">
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
          <MenuItem to="/">Home</MenuItem>
          <MenuItem to="/">Impressum</MenuItem>
          <MenuItem to="/">Datenschutz</MenuItem>
          <MenuItem to="contact">Kontakt</MenuItem>
          <MenuItem to="about">Über uns</MenuItem>
          <MenuItem to="/">Jobs</MenuItem>
          <MenuItem to="/">News</MenuItem>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default MainMenu;
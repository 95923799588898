import React from 'react';
import { BoxDiv } from '../../Components/StyledComponents';
import { Col, Row } from 'react-bootstrap';
import IconBox from '../../Components/IconBox';
import CenterHeading from '../../Components/CenterHeading';

const IconBoxList = [
    {
        icon: '/images/TreatmentPlan.webp',
        title: 'Behandlungsplan Übersicht',
        discription: 'Roger verwaltet alle aktuellen Behandlungspläne an einem Ort. Durch Rogers Schnittstelle brauchen Sie nichts manuell erfassen.'
    },
    {
        icon: '/images/Automatic.webp',
        title: 'Automatische Aufgaben',
        discription: 'Roger erstellt und vergibt wo nötig selbständig Aufgaben an Ihr Team. Sie können auch Ihre eigenen Aufgaben erstellen und vergeben.'
    },
    {
        icon: '/images/AutomaticTracking.webp',
        title: 'Automatische Nachverfolgung',
        discription: 'Roger erinnert Ihre Patienten automatisch an überfällige Behandlungspläne. So entlastet er die ZMV und optimiert den Praxisumsatz.'
    },
    {
        icon: '/images/BusinessWhatsApp.webp',
        title: 'Business-WhatsApp',
        discription: 'Treffen Sie Ihre Patienten da, wo sie sind. Nutzen Sie Business-WhatsApp DSGVO-konform über mehrere Computer, auch von zuhause aus'
    },
    {
        icon: '/images/UniversalInbox.webp',
        title: 'Universelle Inbox',
        discription: 'Erhalten und beantworten Sie Patientenanfragen zentral im Roger Chat. Roger integriert mit allen gängigen Kanälen: E-Mail, SMS und WhatsApp.'
    },
    {
        icon: '/images/PerformanceReport.webp',
        title: 'Performance Report',
        discription: 'Überprüfen Sie die Umsetzungsquoten von Behandlungsplänen und offenen Aufgaben je  Patient und Behandler.'
    },
    {
        icon: '/images/5-StarRatings.webp',
        title: '5-Sterne Bewertungen (bald)',
        discription: 'Roger erkundigt sich nach auserwählten Terminen selbständig bei Ihren Patienten. 5-Sterne Bewertungen enden auf Google.'
    },
    {
        icon: '/images/AppointmentReminders.webp',
        title: 'Termin-Erinnerungen (bald)',
        discription: 'Roger erinnert Patienten rechtzeitig an ihre Termine. Änderungswünsche von Patienten sehen Sie direkt im Roger Chat.'
    },
]


function ModulesSection(props) {
    return (
        <>
            <BoxDiv style={{ marginTop: props.mt + 'px', width: props.width + '%', marginLeft: 'auto', marginRight: 'auto' }}>
                <CenterHeading
                    image='/images/MiroodlesColor.webp'
                    blueHeading='Module'
                    mainHeading='HKP-Hero Fähigkeiten'
                    discription='HKP-Hero integriert mit Ihrer Praxis-Software. So identifiziert er überfällige Behandlungspläne, 
                    geht diesen selbständig via E-Mail, SMS oder Business-WhatsApp nach und erstellt wo nötig 
                    Aufgaben für Ihre ZMV und Rezeption.'
                />
            </BoxDiv>
            <Row className='mt-5'>
                {IconBoxList.map((item, index) => {
                    return <Col md={6} className='mt-5' key={`box-item-${index}`}>
                        <BoxDiv className='w-md-77 mx-auto'>
                            <IconBox icon={item.icon} title={item.title} discription={item.discription} />
                        </BoxDiv>
                    </Col>

                })}
            </Row>
        </>
    );
}

export default ModulesSection;
import React from 'react';
import { BodyText, BoxDiv, DisplayStart, TestimonialImg } from './StyledComponents';

function TestimonialItem(props) {
    const { Data } = props;
    return (
        <>
            {Data.map((item, index) => {
                return <DisplayStart verticalAlign='start' key={`testimonial-item-${index}`}>
                    <BoxDiv className='me-4'>
                        <TestimonialImg src={item.image} />
                    </BoxDiv>
                    <BoxDiv>
                        <BodyText FontSize='20px' FontWeight='700' TextColor='#000' mb='40px'>{item.text}</BodyText>
                        <BodyText FontSize='17px' FontWeight='400' TextColor='#000'>{item.name}</BodyText>
                        <BodyText FontSize='17px' FontWeight='400' TextColor='#000' mb='40px'>{item.organization}</BodyText>
                    </BoxDiv>
                </DisplayStart>
            })}
        </>
    );
}

export default TestimonialItem;
import React from 'react';
import Header from './Header';
import { Outlet } from 'react-router-dom';
import { ContentWrapper, GLobalcss } from '../../Components/StyledComponents';
import Footer from './Footer';

function MainLayout() {

  return (
    <>
      <GLobalcss />
      <Header />
      <ContentWrapper>
        <Outlet />
      </ContentWrapper>
      <Footer />
    </>
  );
}

export default MainLayout;
import React from 'react';
import { BoxDiv } from '../../Components/StyledComponents';
import CenterHeading from '../../Components/CenterHeading';
import TestimonialItem from '../../Components/TestimonialItem';

const TestimonialData=[
    {
        image:'/images/testimonial1.webp',
        text:`"Systematische Plan-Nachverfolgungs-Prozesse machen eine Praxis erfolgreich. Roger macht's genau richtig."`,
        name:'Dr. Stephan Ziegler',
        organization:'Inhaber KU64 Berlin'
    },
    {
        image:'/images/testimonial2.webp',
        text:`"Roger steht für Umstellung von praxis- auf patientenzentrierte Prozesse."`,
        name:'Christian Henrici',
        organization:'Inhaber OPTI Health'
    },
    {
        image:'/images/testimonial3.webp',
        text:`"Patienten gewinnt und hält man heute über moderne Kommunikationskanäle. WhatsApp ist die neue E-Mail!"`,
        name:'Dr. Stefan Helka',
        organization:'Inhaber Implantatzentrum Herne'
    },
]
function TestimonialSection(props) {
    return (
        <>
            <BoxDiv style={{ marginTop: props.mt + 'px', width: props.width + '%', marginLeft: 'auto', marginRight: 'auto' }}>
                <CenterHeading
                    image='/images/testimonials.webp'
                    blueHeading='Bewährt'
                    mainHeading='Rogers Erfolgsgeschichten'
                />
                <BoxDiv style={{ marginTop: '70px' }}>
                    <TestimonialItem Data={TestimonialData}/>
                </BoxDiv>
            </BoxDiv>
        </>
    );
}

export default TestimonialSection;